<template>
  <i-modal
    :title="$t('editing', { value: $tc('subdomain', 1) })"
    :value="value"
    :load="loading"
    @submit="submit"
    @input="$emit('input', $event)"
  >
    <v-row style="display: inline-flex" align="center" justify="center">
      <v-col v-if="subDomain != undefined && field == 'approved'">
        <v-switch v-model="subDomain.approved" :label="$t('approved')">
        </v-switch>
      </v-col>
      <v-col v-if="subDomain != undefined && field == 'subdomain'">
        <v-skeleton-loader v-if="loading" type="text" tile></v-skeleton-loader>
        <v-text-field
          color="secondary"
          key="code-input"
          outlined
          v-model="subDomain.schema_name"
          v-show="!loading"
          :label="$tc('name', 1)"
        />
      </v-col>
      <dialChangeStatus></dialChangeStatus>
    </v-row>
  </i-modal>
</template>
<script>
import { mapGetters } from 'vuex'
import dialChangeStatus from '../email/changeStatus.vue'
export default {
  components: {
    dialChangeStatus
  },
  props: {
    value: {
      type: Boolean,
      default: false
    },
    toEdit: {
      type: [Object],
      default: () => null
    },
    field: {
      type: String,
      default: 'approved'
    }
  },
  data() {
    return {
      isLoading: false,
      loading: false,
      editSubdomain: false,
      subDomain: {
        id: '',
        domain_url: '',
        schema_name: '',
        approved: false,
        company: null,
        user: null
      },
      email: ''
    }
  },
  watch: {
    value: {
      immediate: true,
      handler(val) {
        if (val) {
          this.subDomain = this.toEdit
        }
      }
    },
    editSubdomain: {
      handler(val) {
        if (val) {
          this.editSubdomain = val
        }
        this.submit()
      }
    }
  },
  computed: {
    ...mapGetters({
      getDataEmailNotification: 'utils/getDataEmailNotification'
    })
  },
  methods: {
    async getSubDomain() {
      if (this.field == 'subdomain') {
        const subDomains = await this.$api.subDomain.list({})
        this.subDomains = subDomains.data.results
        this.found = this.subDomains.filter(
          (item) => item.schema_name == this.subDomain.schema_name
        )
        if (this.found.length == 0 && this.subDomain.schema_name != '') {
          this.editSubdomain = true
        } else if (this.found.length > 0) {
          this.$toast.error(
            `${this.$tc('subdomain', 1)} ${this.$tc('rejected', 1)}`
          )
        }
      }
    },
    async getCompanyContacts() {
      const companyContact = await this.$api.company.contact.list({})
      let contacts = companyContact.data.results
      this.email = contacts.filter(
        (item) => item.company_info == this.subDomain.company.pk
      )
      this.email = this.email[0].contact
    },
    async submit() {
      this.getSubDomain()
      this.getCompanyContacts()
      if (this.editSubdomain || this.field === 'approved') {
        this.$api.subDomain
          .edit({
            pk: this.subDomain.id,
            form: this.subDomain
          })
          .then((response) => {
            if (response.status == 200 && response.statusText == 'OK') {
              this.$emit('done')
              this.$emit('input', false)
              this.$toast.success(
                `${this.$tc('subdomain', 1)} ${this.$tc('edited', 2)}`
              )
            }
            if (this.field === 'approved' && this.subDomain.approved === true) {
              let dataEmail = {
                active: true,
                subject: this.$t('email_subdomain', {
                  value: this.subDomain.domain_url
                }),
                recipient_list: this.email
              }
              this.$store.dispatch(
                'utils/openDialogEmailNotification',
                dataEmail
              )
            }
          })
      }
    }
  }
}
</script>
