<route>
{
  "meta": {
    "permission": [
      "subdomains.view_subdomain"
    ]
  }
}
</route>

<template>
  <div class="px-3">
    <dialsubdomain
      v-model="dialog"
      :to-edit="toEdit"
      :field="field"
      @done="reload = true"
    />
    <i-table
      app="subdomains.subdomain"
      dontRemove
      dontEdit
      :headers="headers"
      :items="contact_list"
      :reload.sync="reload"
      :title="$tc('subdomain', 2)"
      @click:create="changeRoute({ pk: 'create' })"
    >
      <template v-slot:item.approved="{ item }">
        <v-col class="pt-0">
          <i-btn
            :title="$tc('approved', 1)"
            classes="m-1"
            text
            class="mx-2 mb-1"
            :color="item.approved ? 'secondary' : 'red'"
            :icon="item.approved ? 'fa-check' : 'fa-times'"
          />
          <i-btn
            v-if="isAdmin"
            :title="$tc('edit', 1)"
            classes="mx-1"
            outlined
            class="mx-2 mb-1"
            :color="'info'"
            :icon="'mdi-pencil'"
            @click="open(item, 'approved')"
          />
        </v-col>
      </template>
      <template v-slot:item.schema_name="{ item }">
        <v-col class="pt-0 text-center text-lg-left">
          {{ item.schema_name }}
          <!--
          * Nota:
          * Este dato no se debería poder editar
          *
          <i-btn
            classes="ml-lg-2"
            outlined
            :color="'info'"
            :icon="'mdi-pencil'"
            :title="$tc('edit', 1)"
            @click="open(item, 'subdomain')"
          />
          -->
        </v-col>
      </template>
      <template v-slot:item.company="{ item }">
        {{
          contact_list !== undefined &&
          contact_list.filter((m) => m.company_info == item.company.pk)[0] !==
            undefined
            ? contact_list.filter((m) => m.company_info == item.company.pk)[0]
                .contact
            : ''
        }}
      </template>
    </i-table>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import dialsubdomain from '../../components/subDomain/subDomain.vue'
export default {
  components: {
    dialsubdomain
  },
  data() {
    return {
      field: '',
      dialog: false,
      toEdit: null,
      reload: false,
      contact_list: []
    }
  },
  computed: {
    headers() {
      return [
        {
          text: this.$tc('subdomain', 1),
          value: 'schema_name'
        },
        {
          text: this.$t('company_name'),
          value: 'company.name'
        },
        {
          text: this.$t('address'),
          value: 'company.address'
        },
        {
          text: this.$t('city'),
          value: 'company.city'
        },
        {
          text: this.$t('state'),
          value: 'company.state'
        },
        {
          text: this.$t('zip_code'),
          value: 'company.zip_code'
        },
        {
          text: this.$tc('email', 1),
          value: 'company'
        },
        {
          text: this.$t('web_url'),
          value: 'company.web_url'
        },
        {
          text: this.$t('approved'),
          value: 'approved'
        }
      ]
    },
    ...mapGetters({
      isAdmin: 'session/isAdmin',
      me: 'session/me'
    })
  },
  methods: {
    open(item, field) {
      this.toEdit = item === undefined ? null : item
      this.field = field
      this.dialog = true
    },
    /**
     * subdomainsQuery
     * Método para consultar y filtrar los subdominios que le pertenecen al
     * usuario que inició sesión.
     * Si el usuario isAdmin, muestra todos los subdominios registrados.
     * Si el usuario tiene una compañía asociada, mostrará solamente esos
     * registros siempre que no sea isAdmin.
     * Si el usuario no tiene compañía asociada no se mostrará nada.
     *
     * Angelo Osorio <danielking.321 at gmail.com>
     */
    async subdomainsQuery() {
      const subdomains = await this.$api.subDomain.list({})
      if (this.isAdmin) {
        this.contact_list = subdomains.data.results
      } else {
        if (this.me.companies.length > 0) {
          for (let index = 0; index < this.me.companies.length; index++) {
            const element = this.me.companies[index].id

            let filtering_records = subdomains.data.results.filter(
              (subdominio) => subdominio.company.pk == element
            )

            if (filtering_records.length > 0) {
              this.contact_list.push(filtering_records[0])
            }
          }
        }
      }
    },
    /**
     * changeRoute
     * Método para redireccionar al formulario de crear subdominio
     *
     * Angelo Osorio <danielking.321 at gmail.com>
     */
    changeRoute(item) {
      this.$router.push({
        name: 'subdomain-pk',
        params: { pk: this.codification({ code: item.pk }) }
      })
    }
  },
  mounted() {
    this.subdomainsQuery()
  }
}
</script>
